import Vue from 'vue';
import VueGtag from 'vue-gtag';

/*
//export default ({ app }) => {
//  Vue.use(VueGtag, {
//    config: { id: 'G-07VQVT4JXM', send_page_view: true },
//    bootstrap: true,
//    enabled: true,
//    appName: 'Facta',
//    pageTrackerScreenviewEnabled: true,
//    pageTrackerEnabled: true,
//    send_page_view: true,
//    sendPageView: true,
//    includes: [
//      {
//        id: 'AW-16616229009',
//      }
//    ],
//  }, app.router);
//}
*/
// conta da Facta foi alterada para usar a conta da Minucred
export default ({ app }) => {
  Vue.use(VueGtag, {
    config: { id: 'UA-206018918-1', send_page_view: true },
    bootstrap: true,
    enabled: true,
    appName: 'Minucred',
    pageTrackerScreenviewEnabled: true,
    pageTrackerEnabled: true,
    send_page_view: true,
    sendPageView: true,
    includes: [
      {
        id: 'AW-377005775',
      },
      {
        id: 'G-FV7PBQ0ENG'
      }
    ],
  }, app.router);
}
